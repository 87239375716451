import CustomNavbar from "./components/Navbar";
import { Routes, Route} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';
import Summary from "./pages/Summary";
import AboutMe from "./pages/AboutMe";
import Resume from "./pages/Resume";
import ContactMe from "./pages/ContactMe";
import MyWork from "./pages/MyWork";
import './App.css'
import React from 'react';

function useWindowDimensions() {
  const [width, setWidth] = React.useState(window.innerWidth);
  const [height, setHeight] = React.useState(window.innerHeight);

  const updateWidthAndHeight = () => {
    setWidth(window.innerWidth);
    setHeight(window.innerHeight);
  };

  React.useEffect(() => {
    window.addEventListener("resize", updateWidthAndHeight);
    return () => window.removeEventListener("resize", updateWidthAndHeight);
  });

  return {
    width,
    height,
  }
}

function App() {

  const { width, height } = useWindowDimensions()

  return (
    <div className="App">
      <CustomNavbar/>
      <Routes>
        <Route path="/" element={<Summary/>}/>
        <Route path="about" element={<AboutMe/>}/>
        <Route path="resume" element={<Resume/>}/>
        <Route path="contact" element={<ContactMe/>}/>
        <Route path="work" element={<MyWork/>}/>
      </Routes>
    </div>
  );
}

export default App;
