import { Container, Navbar, Nav, Button } from "react-bootstrap";
import React from 'react'
import 'bootstrap/dist/css/bootstrap.css'
import sign from '../images/sign.png';
import { Link } from "react-router-dom";
import '../extra.css';

export default function CustomNavbar() {
    return (
        window.innerWidth > 768?
            <Navbar className="justify-between" style={{backgroundColor:"#000000"}}>
                <Container>
                    <Link to="/"><img src={sign} alt="Vedant Kulkarni" width="130px" height="50px"/></Link>
                    <div>
                        <Nav className="me-auto" >
                            <span style={{paddingRight:"20px"}}>
                            <Nav.Link className="navlink" href="/" style={{color:"#fff", margin:"0px", padding:"0px", fontSize:"17px"}}>SUMMARY</Nav.Link>
                            </span>
                            <span style={{paddingRight:"20px"}}>
                            <Nav.Link className="navlink" href="about" style={{color:"#fff", margin:"0px", padding:"0px", fontSize:"17px"}}>ABOUT ME</Nav.Link>
                            </span>
                            <span style={{paddingRight:"20px"}}>
                            <Nav.Link className="navlink" href="work" style={{color:"#fff", margin:"0px", padding:"0px", fontSize:"17px"}}>MY WORK</Nav.Link>
                            </span>
                            <span style={{paddingRight:"20px"}}>
                            <Nav.Link className="navlink" href="resume" style={{color:"#fff", margin:"0px", padding:"0px", fontSize:"17px"}}>RESUME</Nav.Link>
                            </span>
                        </Nav>
                    </div>
                    <Link to="contact">
                        <Button style={{backgroundColor:"#E50914", borderColor:"#E50914"}}>Contact Me</Button>
                    </Link>
                </Container>
            </Navbar>
        :
            <Navbar className="justify-content-center" collapseOnSelect expand="lg"  variant="dark" style={{backgroundColor:"#000000"}}>
                <Container>
                    <Navbar.Brand href="/">Vedant Kulkarni</Navbar.Brand>
                    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
                    <Navbar.Collapse id="responsive-navbar-nav" style={{"textAlign":"center"}}>
                        <Nav className="me-auto" >
                            <Nav.Link href="/" style={{color:"#ffffff", fontSize:"17px", margin:"0px 10px 0px 10px"}}>Summary</Nav.Link>
                            <Nav.Link href="about" style={{color:"#ffffff", fontSize:"17px", margin:"0px 10px 0px 10px", fontStyle:"Bebas Neue"}}>About Me</Nav.Link>
                            <Nav.Link href="work" style={{color:"#ffffff", fontSize:"17px", margin:"0px 10px 0px 10px", fontStyle:"Bebas Neue"}}>My Work</Nav.Link>
                            <Nav.Link href="resume" style={{color:"#ffffff", fontSize:"17px", margin:"0px 10px 0px 10px", fontStyle:"Bebas Neue"}}>Resume</Nav.Link>
                        </Nav>
                        <Link to="contact">
                            <Button style={{backgroundColor:"#E50914", borderColor:"#E50914"}}>Contact Me</Button>
                        </Link>
                    </Navbar.Collapse>
                </Container>
            </Navbar>

        
    );
}