import React from 'react';
import Skills from './Skills';
import {Row, Container, Col, Button} from 'react-bootstrap';
import '../textstyles.css';
import 'bootstrap/dist/css/bootstrap.css';
import Typewriter from 'typewriter-effect';
import { Link } from 'react-router-dom';

export default function Bioskill() {
    return (
        <Container>  
            <Row >
                <Col md>
                    <div style={{margin: "20% 0%"}}>
                        <h1 className="p-3 font-primary font-bold">
                        <span> Hi, I am </span>
                        <span  style={{color:"#E50914"}}>Vedant Kulkarni.</span>
                        <span style={{fontFamily:"consolas, Copperplate", fontWeight:"bold", color:"#E50914"}}><Typewriter
                            className="p-3"
                            options={{
                                strings: ['Web Developer', 'App Developer'],
                                autoStart: true,
                                loop: true,
                            }}
                        />
                        </span> 
                        </h1>
                        <h5 className="p-3" style={{fontFamily: "netflix"}}>
                        I am Engineering Undergraduate at Pune Institute of Computer Technology (PICT) with expertise in App and Web development. Dedicated to better management of projects. Strong passion for Software development. Enthusiastic about learning new things. Experience of working and collabrating with team members to achieve goals. Trilingual in English, Marathi and Hindi.
                        </h5>
                        <Link to="resume">
                            <Button style={{backgroundColor:"#ffffff", borderColor:"#E50914", color:"#E50914", margin:"0px 10px 0px 10px"}}>Resume</Button>
                        </Link>
                        <Link to="contact">
                            <Button style={{backgroundColor:"#ffffff", borderColor:"#E50914", color:"#E50914", margin:"0px 10px 0px 10px"}}>Contact Me</Button>
                        </Link>
                    </div>
                </Col>
                <Col md>
                    <h1 className="customHeading" style={{"marginTop":"30px"}}>
                        Skills
                    </h1>
                    <Skills/>
                </Col>
            </Row>
        </Container>
    )
}
