import React from 'react'
import { Col, Button} from 'react-bootstrap'
import "bootstrap/dist/css/bootstrap.css"
import Footer from '../components/Footer'

export default function Resume() {
    return (
        <div className='row' style={{backgroundColor:"#000000"}}>
                <Col className="col-2"></Col>
                <Col style={{backgroundColor:"rgb(48,48,48)"}}>
                    <div style={{backgroundColor:"rgb(48,48,48)", padding:"20px 0px 20px 20px", display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                        <h5 style={{color:"#ffffff"}}>Resume | Vedant Kulkarni</h5>
                        <a href="https://drive.google.com/u/1/1WZcLDbR_p7T8pqw963eQGtBRiQv8C226&export=download" download>
                            <Button style={{color:"#ffffff", backgroundColor:"#000000", borderColor:"#ffffff"}}>
                                Download
                            </Button>
                        </a>
                    </div>
                    <iframe className='col-12' src="https://drive.google.com/file/d/1WZcLDbR_p7T8pqw963eQGtBRiQv8C226/preview" 
                        width="900" height={window.innerWidth>480?"1200":"400"} allow="autoplay" title="resume">
                    </iframe>
                </Col>
                <Col className="col-2"></Col>
                <Footer position={Window.innerWidth<786?"absolute":null}/>
        </div>
    )
}