import React from 'react';
import Flutter from '../icons/flutter.png';
import Python from '../icons/python.png';
import R from '../icons/react.png';
import Css from '../icons/css.png';
import Firebase from '../icons/firebase.png';
import Html from '../icons/html.png'; 
import Java from '../icons/java.png';
import Sql from '../icons/sql.png';
import Git from '../icons/git.png';
import C from '../icons/c++.png';
import Javascript from '../icons/javascript.png';
import Dart from '../icons/dart.png';
import {Card, Row} from 'react-bootstrap';

const icons = [Flutter, R, Python, Css, Html, Firebase, Java, Sql, Git, C, Dart, Javascript];
const titles = ['Flutter', 'React', 'Python', 'CSS', 'HTML', 'Firebase', 'Java', 'Sql', 'Git', 'C/C++', 'Dart', 'Javascript'];

export default function Skills() {
    return (
        <Row>
            {icons.map((item,i)=> 
                <Card key={i} style={{ width:window.innerWidth>450?'8rem':"25%", margin:"30px 15px 0px 15px", borderColor:"#fff"}}>
                    <Card.Img variant="top" src={item} style={{opacity:0.77}}/>
                    <Card.Title style={{textAlign: "center", marginTop:"15px"}}>{titles[i]}</Card.Title>
                </Card>
            )}
        </Row>
    )
}
