import QuotesRotator from "./RecommendCard";
import { Col, Row } from "react-bootstrap";
import "../textstyles.css"

const quotes = [
    {
        //title: "50 Inspirational Quotes For Startups And Entrepreneurs",
        content:
            "Vedant has worked under me as an intern. He handled our Website assignment with aplomb and diligence, displaying the ability to follow direction while adding his own creative input in a zeal and useful way. He makes sure all the deadlines are met and whatever project he is working on meets the highest standards. I recommend him to anyone who wants to hire him.",
        footnote: "Akshay Patil, Product Consultant at iMocha",
        pic: "akshaypatil.jpeg",
        link: "https://www.linkedin.com/in/akshayjpatil/"
    }
];

export default function Recommendations() {
  return (
      <Col style={{padding:"20px",backgroundColor:"#fff"}}>
          <div className="container" style={{display: "flex",alignItems: "center"}}>
        <div className="border" style={{borderBottom: "1px dashed green", width: "100%"}} />
            <span className="content" style={{padding: "0px 30px 0px 30px"}}>
                <h1 className='customHeading'>Recommendation</h1>    
            </span>
        <div className="border" style={{borderBottom: "1px dashed black", width: "100%"}}/>
        </div>
        <Row>
            <QuotesRotator
            className = "c"
            data={quotes}
            progressBarColor="#E50914"
            backgroundColor="#fff"
            textColor="rgb(78,78,78)"
            timer="250000"
            />
        </Row>
      </Col>
  )
}
