import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

import { Col, Row, Button} from 'react-bootstrap';
import '../textstyles.css'

const ContactForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm();
  
  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Form sent!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'submit-feedback success',
      toastId: 'notifyToast'
    });
  };
  
  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async (data) => {
    // Destrcture data object
    const { name, email, subject, message } = data;
    try {
      const templateParams = {
        name,
        email,
        subject,
        message
      };

      await emailjs.send(
        //process.env.REACT_APP_SERVICE_ID,
        //process.env.REACT_APP_TEMPLATE_ID,
        //process.env.REACT_APP_USER_ID
        'service_u9l6iwp',
        'template_5d50nb8',
        templateParams,
        'user_uhZCoJ0gLrHqo6UIH2NYe'
      );

      reset();
      toastifySuccess();
    } catch (e) {
      
    }
  };

  return (
    <div style={{backgroundColor:"rgb(255,255,255)", width:"100%", padding:window.innerWidth>786? "0% 20% 0% 20%" : "0% 10px 0% 10px"}}>
      <Col className='col-12' style={{padding:"20px 20px 20px 20px"}}>
        <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
          <Row style={{display:"flex", flexDirection:window.innerWidth>786?"row":"column"}}>
            <Col className={window.innerWidth>786?'col-6':'col-12'}>
              <div className='mb-4'>
                <h5>Name</h5>
                <input
                style={{backgroundColor:"rgb(245,245,245)", borderColor:"rgb(190,190,190)", borderRadius:"0px 0px 0px 0px", borderWidth:"0px 0px 2.5px 0px"}}
                  type='text'
                  name='name'
                  {...register('name', {
                    required: { value: true, message: 'Please enter your name' },
                    maxLength: {
                      value: 30,
                      message: 'Please use 30 characters or less'
                    }
                  })}
                  className='form-control formInput'
                  placeholder='Name'
                ></input>
                {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
              </div>
              <div className='mb-4'>
                <h5> Email</h5>
                <input
                  type='email'
                  name='email' style={{backgroundColor:"rgb(245,245,245)", borderColor:"rgb(190,190,190)", borderRadius:"0px 0px 0px 0px", borderWidth:"0px 0px 2.5px 0px"}}
                  
                  {...register('email', {
                    required: true,
                    pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                  })}
                  className='form-control formInput'
                  placeholder='Email address'
                ></input>
                {errors.email && (
                  <span className='errorMessage'>Please enter a valid email address</span>
                )}
              </div>
              <div>
              <h5>Phone No.</h5>
                <input
                  name='subject'
                  style={{backgroundColor:"rgb(245,245,245)", borderColor:"rgb(190,190,190)", borderRadius:"0px 0px 0px 0px", borderWidth:"0px 0px 2.5px 0px"}}
                  {...register('subject', {
                    required: { value: true, message: 'Please enter phone number' },
                    maxLength: {
                      value: 10,
                      message: 'Enter valid phone number',
                    }
                  })}
                  className='form-control formInput'
                  placeholder='Phone Number'
                  type="number"
                ></input>
                {errors.subject && (
                  <span className='errorMessage'>{errors.subject.message}</span>
                )}
              </div>  
            </Col>
            <div className={window.innerWidth>786?'col-6':'col-12'}>
            <h5>Message</h5>
              <textarea
              style={{backgroundColor:"rgb(245,245,245)", borderColor:"rgb(190,190,190)", borderRadius:"0px 0px 0px 0px", borderWidth:"0px 0px 2.5px 0px", height:"230px"}}
                rows={1}
                name='message'
                {...register('message', {
                  required: true
                })}
                className='form-control formInput'
                placeholder='Message'
              ></textarea>
              {errors.message && <span className='errorMessage'>Please enter a message</span>}
            </div>
          </Row>
          <Button className={'submit-btn mt-4 col-'+ window.innerWidth>786?"6":"12"} type='submit' style={{width:window.innerWidth>786?"20%":"30%", margin:"0% 0% 6% 40%", backgroundColor:"#E50914", borderColor:"#fff", color:"#fff", fontWeight:"bold"}}>
            SUBMIT
          </Button>
          <ToastContainer />
        </form>        
      </Col>
      </div>
    );
  };

  export default ContactForm;

  /*<Col className='col-11' style={{display:"flex", flexDirection:"column", backgroundColor:"rgb(48,48,48)", padding:"20px 20px 20px 20px"}}>
        <div className='ContactForm'>
          <div className='container'>
            <div className='row'>
              <div className='col-10 text-center'>
                <div className='contactForm'>
                  <form id='contact-form' onSubmit={handleSubmit(onSubmit)} noValidate>
                    
                    <div className='row formRow'>
                      <div className='col-6 mb-4'>
                        <input
                          type='text'
                          name='name'
                          {...register('name', {
                            required: { value: true, message: 'Please enter your name' },
                            maxLength: {
                              value: 30,
                              message: 'Please use 30 characters or less'
                            }
                          })}
                          className='form-control formInput'
                          placeholder='Name'
                        ></input>
                        {errors.name && <span className='errorMessage'>{errors.name.message}</span>}
                      </div>
                      <div className='col-6 mb-4'>
                        <input
                          type='email'
                          name='email'
                          {...register('email', {
                            required: true,
                            pattern: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
                          })}
                          className='form-control formInput'
                          placeholder='Email address'
                        ></input>
                        {errors.email && (
                          <span className='errorMessage'>Please enter a valid email address</span>
                        )}
                      </div>
                    </div>
                  
                    <div className='row formRow mb-4'>
                      <div className='col'>
                        <input
                          type='text'
                          name='subject'
                          {...register('subject', {
                            required: { value: true, message: 'Please enter a subject' },
                            maxLength: {
                              value: 75,
                              message: 'Subject cannot exceed 75 characters'
                            }
                          })}
                          className='form-control formInput'
                          placeholder='Subject'
                        ></input>
                        {errors.subject && (
                          <span className='errorMessage'>{errors.subject.message}</span>
                        )}
                      </div>
                    </div>

                    <div className='row formRow mb-4'>
                      <div className='col'>
                        <textarea
                          rows={3}
                          name='message'
                          {...register('message', {
                            required: true
                          })}
                          className='form-control formInput'
                          placeholder='Message'
                        ></textarea>
                        {errors.message && <span className='errorMessage'>Please enter a message</span>}
                      </div>
                    </div>
                    <button className='submit-btn' type='submit'>
                      Submit
                    </button>
                  </form>
                </div>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
        </Col>*/