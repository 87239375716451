import React from 'react'
import { useEffect, useRef } from 'react'
import Projects from '../components/Projects'
import Experience from '../components/Experience'
import 'bootstrap/dist/css/bootstrap.css'
import Footer from '../components/Footer'

export default function MyWork({scroll}) {
    const myRef = useRef(null)

    const executeScroll = () => myRef.current.scrollIntoView({behavior: 'smooth', block: 'start'})

    useEffect(()=>{
        if(scroll===true){
            executeScroll();
        }
    })

    return (
        <div>
            <Experience/>
            <Projects innerRef={myRef} num={5} title="All"/>
            <Footer/>
        </div>
    )
}
