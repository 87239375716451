import React from 'react'
import ContactDetails from '../components/ContactDetails'
import ContactForm from '../components/ContactForm'
import '../textstyles.css'
import 'bootstrap/dist/css/bootstrap.css'
import Footer from '../components/Footer'

export default function ContactMe() {
    return (
        <div style={{justifyContent:"center", height:"100%"}}>
            <h1 className='customHeading' style={{margin:"30px 0px 10px 0px"}}>Get in Touch!</h1>
            <ContactDetails/>
            <ContactForm/>
            <Footer position="absolute"/>
        </div>
    )
}
