import React from 'react'
import Bioskill from '../components/Bioskill';
import Projects from '../components/Projects';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import Footer from '../components/Footer';
import Recommendations from '../components/Recommendations';

export default function Summary() {
    return (
        <div>
            <Bioskill/>
            <Projects num={3} title="Recent"/>
            <div style={{display:"flex", justifyContent:"center", paddingBottom:"20px"}}>
                <Link to="work" params={{scroll:true}}>
                    <Button style={{backgroundColor:"#ffffff", borderColor:"#E50914", color:"#E50914", margin:"0px 10px 0px 10px"}}>See All</Button>
                </Link>
            </div>
            <Recommendations/>
            <Footer/>
        </div>
    )
}
