import { Chrono } from "react-chrono";
import React from 'react'
import {FaExternalLinkAlt} from "react-icons/fa";
import { Col, Container } from "react-bootstrap";
import '../textstyles.css'

export default function Experience() {
    const arr = [
        {
            "company" : "iMocha",
            "link" : "https://www.imocha.io/",
            "position" : "SDE Intern",
            "description" : "Worked on developing an api based react website. Also created tests for unit testing the website."
        },
        {
            "company" : "Mettl",
            "link" : "https://mettl.com/",
            "position" : "Subject-Matter Expert(DSA)",
            "description" : "As a SME I was responsible for creating and reviewing DSA based quetions for the assessment platform."
        },
        {
            "company" : "Personal Client",
            "link" : "https://www.linkedin.com/company/finlegal-solutions/about/",
            "position" : "App Developer",
            "description" : "On contract basis, developed an app for the client according to his needs. Used firebase, flutter for developing app."
        },
    ]
    
    return (
        <Col>
            <h1 className="customHeading" style={{marginTop:"30px"}}>Experience</h1>
            <Container>
                <Chrono mode={window.innerWidth > 786?"VERTICAL_ALTERNATING":"VERTICAL"} theme={{cardBgColor: "rgb(48,48,48)",cardForeColor: "white",titleColor: "#E50914", primary:"#E50914", secondary:"#fff"}} 
                    hideControls={true} disableClickOnCircle={true} activeItemIndex={null} timelineCircleDimension={window.innerWidth>768?"55":"10"}>
                    {
                        window.innerWidth>768?
                            <div className="chrono-icons">
                                <img src="https://images.g2crowd.com/uploads/product/image/large_detail/large_detail_f9434e6e274193ac1be4d0508ce1eebe/imocha.png" alt="iMocha" style={{border:"2.95px solid #E50914", borderRadius:"50px", padding:"4px", margin:"0px 0px 0px 0px"}}/>
                                <img src="https://mettl.com/research/wp-content/uploads/sites/11/2018/07/LogoDeliverable_Dark_Light.png" alt="Mettl" style={{border:"2.95px solid #E50914", borderRadius:"50px", padding:"4px", objectFit: "contain", width:"100px", height:"100px", margin:"0px 0px 0px 0px"}}/>
                                <img src="https://w7.pngwing.com/pngs/96/126/png-transparent-client-computer-icons-others-miscellaneous-logo-desktop-wallpaper-thumbnail.png" alt="Personal Client" style={{border:"2.95px solid #E50914", borderRadius:"50px", padding:"4px", margin:"0px 0px 0px 0px"}}/>
                            </div>
                        :
                            null
                    }
                    {
                        arr.map(
                            (item, i) =>
                            <div key={i} style={{"color":"#ffffff", "fontFamily":"netflix-light"}}>
                                <a href={item["link"]} target="_blank" rel="noreferrer" style={{display:"flex", flexDirection:"row", textDecorationColor:"#E50914", textDecorationThickness:"3px"}}>
                                    <h3 className="mb-2" style={{fontSize: "30px", fontStyle: "bold", color: '#fff', textAlign:"left", fontFamily:"netflix"}}>{item["company"]}</h3>        
                                    <FaExternalLinkAlt style={{margin:"10px 10px 10px 10px", color:"#E50914", width:"22px", height:"22px"}}/>
                                </a>
                                <h4>{item["position"]}</h4>
                                <h5>{item["description"]}</h5>
                            </div>
                        )
                    }
                </Chrono>
            </Container>
        </Col>
    )
  }

  