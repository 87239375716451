import React from 'react'
import '../textstyles.css'
import { Card, Col } from 'react-bootstrap';
import { BiLinkAlt, BiCodeAlt} from "react-icons/bi";
import Enlist_Logo from '../images/Enlist_logo.png'
import Legalsoft_Logo from '../images/Legalsoft_logo.png'
import Finit_Logo from '../images/Finit_logo.png'
import Portfoio_Logo from '../images/Portfolio_logo.png'
import Library_Logo from '../images/Library_logo.png'
import 'bootstrap/dist/css/bootstrap.css'

const arr = [Enlist_Logo, Legalsoft_Logo, Finit_Logo, Library_Logo, Portfoio_Logo]

export default function ProjectCard(prop) {
    return (
        <Card style={{ width: '20rem', margin: "20px 20px 20px 20px",padding:"0px 0px 0px 0px", borderColor:"rgb(170,170,170)"}} >
            <Card.Img variant="top" src={arr[prop.ind]} style={{objectFit:"fill", padding:"0px 0px 0px 0px", margin:"0px 0px 0px 0px"}}/>
            <Card.Body style={{justifyContent:"space-evenly"}}>
                <Card.Title style={{textAlign: "center", fontSize: "25px", fontFamily:"netflix", fontWeight:"bold"}}>{prop.item['title']}</Card.Title>
                <Col style={{alignContent:"space-between", height:"100%"}}>
                    <div>
                        {prop.item['description']}
                    </div>
                    <div>
                        <hr style={{borderStyle:"dashed"}}/>
                        <nobr style={{display: "flex", flexDirection:"row", width:"100%", justifyContent: "space-evenly"}}>
                            <div style={{borderColor:"#E50914", borderStyle:"solid", borderRadius:"35px", padding:"5px 5px 5px 5px", borderWidth:"2px"}}>
                                <a href={ prop.item['link'] }> <BiLinkAlt style={{color:"#E50914", height:"25px", width:"25px"}}/> </a>
                            </div>
                            {
                                prop.item['show']?
                                    <div style={{borderColor:"#E50914", borderStyle:"solid", borderRadius:"35px", padding:"5px 5px 5px 5px", borderWidth:"2px"}}>   
                                        <a href={ prop.item['codeLink'] }> <BiCodeAlt style={{color:"#E50914", height:"25px", width:"25px"}}/> </a>
                                    </div> 
                                    :
                                    null
                            }    
                        </nobr>
                    </div>
                </Col>
            </Card.Body>
        </Card>
    )
}
