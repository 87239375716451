import React from 'react'
import {Container, Row} from 'react-bootstrap';
import { VerticalTimeline, VerticalTimelineElement}  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {FaGraduationCap} from "react-icons/fa";
import '../textstyles.css';
import '../extra.css'

export default function Education() {
    const arr = [
        {
            "school" : "Pune Institute of Computer Technology, Pune",
            "degree" : "Bachelor of Engineering",
            "result" : "CGPA: 9.16",
            "date" : "2019-Current"
        },
        {
            "school" : "Sanjay Ghodawat Junior College",
            "degree" : "Higher Secondary",
            "result" : "Percentage: 82.92",
            "date" : "2017-2019"
        },
        {
            "school" : "Sulakhe Highschool, Barshi",
            "degree" : "Secondary",
            "result" : "Percentage: 91.4",
            "date" : "2011-2017"
        }
    ];

    return (
        <Container>
        <Row>
            <div className="container" style={{display: "flex",alignItems: "center"}}>
                    <div className="border" style={{borderBottom: "1px dashed green", width: "100%"}} />
                    <span className="content" style={{padding: "0px 30px 0px 30px"}}>
                        <h1 className='customHeading'>Education</h1>    
                    </span>
                    <div className="border" style={{borderBottom: "1px dashed black", width: "100%"}}/>
            </div>
            <Container className='col-9' style={{ "fontFamily":"netflix-light"}}>
            <VerticalTimeline lineColor="#E50914" style={{borderColor:"#fff"}}>
                {
                    arr.map((item,i)=>
                        <VerticalTimelineElement
                            key={i}
                            date={item['date']}
                            dateClassName='dateClass'
                            iconStyle={{ background: '#ffffff', color: 'rgb(0, 0, 0)', border:"4px solid #E50914", boxShadow:"none"}}
                            contentStyle={{backgroundColor:"rgb(48,48,48)", color:"#fff", borderRadius:"0px 0px 0px 0px"}}
                            contentArrowStyle={{ borderRight: '7px solid  rgb(0, 0, 0)'}}
                            icon={<FaGraduationCap/>}>
                                <h2 style={{fontSize: "30px", fontStyle: "bold",color:"#fff"}}>{item["school"]}</h2>
                                <h4>{item["degree"]}</h4>
                                <h4>{item["result"]}</h4>
                        </VerticalTimelineElement>
                    )
                }                   
                </VerticalTimeline>
            </Container>
        </Row>
        </Container>
    )
}