import React from 'react'
import { Row } from 'react-bootstrap'
import { MdLocationOn, MdCall, MdForum } from "react-icons/md";
import 'bootstrap/dist/css/bootstrap.css'

export default function ContactDetails() {
    const arr = [
        {
            "icon" :  <MdLocationOn style={{height:"70px", width:"70px"}}/>,
            "detail" : "Pune",
            "link" : "https://goo.gl/maps/WL7ZXeGNv9Ra2zYK7"
        },
        {
            "icon" : <MdCall style={{height:"70px", width:"70px"}}/>,
            "detail" : "+919405923701",
            "link" : "tel:+919405923701"
        },
        { 
            "icon" : <MdForum style={{height:"70px", width:"70px"}}/>,
            "detail" : "kulkarnivedant00@gmail.com",
            "link" : "mailto:kulkarnivedant00@gmail.com"
        }
    ]

    return (
            <Row style={{padding:window.innerWidth>786? "0% 25% 0% 25%":"0px 0px 0px 0px"}}>
                {
                    arr.map(
                        (item,i) => 
                        <a href={item['link']}  target="_blank" className="col" rel="noreferrer" key={i} style={{justifyContent:"center", color:"rgb(70,70,70)", margin:"0px"}}>
                            <Row style={{justifyContent:"center", height:"70px"}}>{item['icon']}</Row>
                            <h5 style={{textAlign:"center"}}>{item['detail']}</h5>
                        </a>
                    )
                }
            </Row>
    )
}
