import React from 'react'
import { Col } from 'react-bootstrap'
import '../textstyles.css';

export default function Bio() {
    return (
        <Col style={{padding:"30px 20% 30px 20%"}}>
            <h1 className="customHeading">Bio</h1>
            <h5 style={{fontFamily: "netflix", textAlign:"center"}}>
                I am Engineering Undergraduate at Pune Institute of Computer Technology (PICT) with expertise in App and Web development. 
            </h5>
            <h5 style={{fontFamily: "netflix", textAlign:"center"}}>
                Dedicated to better management of projects. Strong passion for Software development. Enthusiastic about learning new things.  
            </h5>
            <h5 style={{fontFamily: "netflix", textAlign:"center"}}>
                Experience of working and collabrating with team members to achieve goals. Trilingual in English, Marathi and Hindi.
            </h5>
        </Col>
    )
}
