import React from 'react'
import Education from '../components/Education'
import Bio from '../components/Bio'
import Footer from '../components/Footer'
import Organizations from '../components/Organizations'

export default function AbotMe() {
    return (
        <div>
            <Bio/>
            <Education/>
            <Organizations/>
            <Footer/> 
        </div>
    )
}
