import React from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import '../textstyles.css'
import ProjectCard from './ProjectCard';
import 'bootstrap/dist/css/bootstrap.css'

export default function Projects(props) {
    const arr = [
        {
            "link" : "https://play.google.com/store/apps/details?id=com.enlist.android",
            "codeLink" : "empty",
            "title" : "Enlist",
            "description" : "An app facilitating users to create almost any type of list such as task, shopping & recipe, according to needs. User can share list, update, add, create, delete items in their list, add images for items. App can also set reminders at a given time. App stores data in sqlite3 database. App is built using Flutter.",
            "tag" : ['r','y'],
            "show" : false
        },
        {
            "link" : "https://legalsoft.netlify.app/",
            "codeLink" : "https://github.com/vmkgalvatron/legalsoft",
            "title" : "Legalsoft",
            "description" : "Developed a web app and a flutter package that helps app developers to generate privacy policies for app. Users have to login and fill the info about their app. Our website will generate a unique app ID which they can use in the flutter package and have their privacy policy in their app. Built using React, Flutter, Firebase",
            "tag" : ['r','y'],
            "show" : true
        },
        {
            "link" : "https://juggernaut-finit.netlify.app/",
            "codeLink" : "https://github.com/vmkgalvatron/finit",
            "title" : "Finit",
            "description" : "A web app that has basic but useful information about each form of investment. Users are asked some factors, based on which app is provides suggestions about investment to users. App is meant for demonstration purpose and can be expanded further. App is created using React JS, Bootstrap.",
            "tag" : ['r','y'],
            "show" : true
        },
        {
            "link" : "https://drive.google.com/file/d/1QEUgDZzpRohkLSk9ePMZ8QbPC9khOO9f/view?usp=sharing",
            "codeLink" : "https://github.com/vmkgalvatron/Library_Sotware",
            "title" : "Library Software",
            "description" : "Basic Library Software with excel register. Uses excel sheet for storing data. Created using python, tkinter and excel.",
            "tag" : ['r','y'],
            "show" : true
        },
        {
            "link" : "https://sentry.studio/",
            "codeLink" : "empty",
            "title" : "Portfolio Website",
            "description" : "This portfoio website. Website created using React and Email JS.",
            "tag" : ['r','y'],
            "show" : false
        }
    ]

    const row = []
    for(var i=0;i<props.num;i++){
        row.push(<ProjectCard key={i} item={arr[i]} ind={i}/>)
    }

    return (
        <Container>
            <Col style={{margin:"30px 0px 0px 0px"}}>
                <div className="container" style={{display: "flex",alignItems: "center"}}>
                    <div className="border" style={{borderBottom: "1px dashed green", width: "100%"}} />
                    <span className="content" style={{padding: "0px 30px 0px 30px"}}>
                        <h1 className='customHeading'>{props.title} Projects</h1>    
                    </span>
                    <div className="border" style={{borderBottom: "1px dashed black", width: "100%"}}/>
                </div>
                <Row style={{justifyContent:"center"}}>
                    {row}
                </Row>
            </Col>
        </Container>
    )
}
