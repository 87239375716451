import React from "react";
import { Col, Container, Row, Img } from "react-bootstrap";
import '../textstyles.css';
import ACM from "../images/acm.jpeg";
import Codechef from "../images/codechef.jpeg";
import 'bootstrap/dist/css/bootstrap.css'

export default function Organizations() {
    const orgs = [
        {name : "PICT Codechef Chapter", desc:"2020-Present",pos:"Executive Team Member",img: Codechef},
        {name : "PICT ACM Club", desc:"2019-Present",pos:"Content Team Member", img:ACM}
    ];
    return (
        <Container  style={{padding:"30px 0px"}}>
            <Col>
                <div className="container" style={{display: "flex",alignItems: "center"}}>
                    <div className="border" style={{borderBottom: "1px dashed green", width: "100%"}} />
                    <span className="content" style={{padding: "0px 30px 0px 30px"}}>
                        <h1 className='customHeading'>Organizations</h1>    
                    </span>
                    <div className="border" style={{borderBottom: "1px dashed black", width: "100%"}}/>
                </div>
                <div style={{display:"flex", flexDirection:window.innerWidth > 768?"row":"column", padding:"30px 0px"}}>
                    {
                        orgs.map((item,i)=>
                            <Container style={{backgroundColor:"rgb(48,48,48)", padding:"20px 20px", margin:window.innerWidth > 768?"0px 20px":"20px 0px" , color:"#fff"}}>    
                                <Row>
                                    <img src={item.img} style={{width:"150px",height:"150px"}}/> 
                                    <Col>
                                        <h2>{item.name}</h2>
                                        <h3>{item.pos}</h3>
                                        <h5>{item.desc}</h5>
                                    </Col>    
                                </Row>       
                            </Container>
                        )
                    }
                </div>
            </Col>
        </Container>
    );

}