import React from 'react'
import { Row, ModalFooter, Col } from 'react-bootstrap'
import { AiFillLinkedin, AiFillGithub, AiFillInstagram, AiTwotoneMail } from "react-icons/ai";
import 'bootstrap/dist/css/bootstrap.css';

export default function Footer(props) {

    const arr = [
        {'icon':<AiFillLinkedin style={{width:"25px", height:"25px"}}/>, "link":"https://www.linkedin.com/in/vedant-kulkarni-1907441a6"},
        {'icon':<AiFillGithub style={{width:"25px", height:"25px"}}/>, "link":"https://github.com/vmkgalvatron"}, 
        {'icon':<AiFillInstagram style={{width:"25px", height:"25px"}}/>,"link":"https://www.instagram.com/_vmkulkarni_/"}, 
        {'icon':<AiTwotoneMail style={{width:"25px", height:"25px"}}/>, "link":"mailto:kvedant344@gmail.com"}
    ];

    return (
        window.innerWidth>768?
            <ModalFooter style={{height:"50px", padding:"0px 0px 0px 0px", borderWidth:"0px 0px 0px 0px", width:"100%", position:props.position,bottom:"0px"}}>
                <Row style={{color:"white", height:"100%", width:"100%", margin:"0px 0px 0px 0px"}}>
                    <nobr style={{padding:"20px 15% 0px 15%", backgroundColor:"#E50914", justifyContent:"space-between", display:"flex"}}>
                        <span><h3 style={{padding:"0px 0px 2px 0px"}} >Vedant Kulkarni</h3></span>
                        <span>
                            {
                                arr.map(
                                    (item,i)=><a href={item['link']} key={i} style={{color:"white",borderStyle:"solid", borderRadius:"40px", padding:"10px 7px 10px 7px", margin:"0px 7px 0px 7px", borderWidth:"1px", textDecoration:"none"}}>{item["icon"]}</a>
                                )
                            }
                        </span>
                    </nobr>
                </Row> 
            </ModalFooter>
        :
            <ModalFooter style={{ padding:"20px 0px 0px 0px", borderWidth:"0px 0px 0px 0px", width:"100%", backgroundColor:"#E50914", borderRadius:"0px 0px 0px 0px"}}>
                <Col style={{color:"white", height:"100%", width:"100%", margin:"0px 0px 0px 0px", textAlign:"center"}}>
                    <span style={{display:"flex", flexDirection:"row", justifyContent:"center", marginBottom:"15px"}}>
                        {
                            arr.map(
                                (item,i)=><a href={item['link']} key={i} style={{color:"white",borderStyle:"solid", borderRadius:"40px", padding:"7px 7px 7px 7px", margin:"0px 7px 0px 7px", borderWidth:"1px", textDecoration:"none"}}>{item["icon"]}</a>
                            )
                        }
                    </span>
                    <h3 style={{padding:"0px 0px 2px 0px"}} >Vedant Kulkarni</h3>
                </Col> 
            </ModalFooter>
    )
}
